<template>
  <div>
    <p class="pl-3 text-lg font-bold sm:text-white"><span class="typcn typcn-edit"></span> Transaksi</p>
    <div v-if="modalbayar" class="row" style="position:fixed;top:60px;right:10px;z-index:1000;width:100vw;">
        <div class="col-sm-9" @click="modalbayar=false" style="height:100vh;"></div>
        <div class="col-sm-3">
            <div class="bg-white rounded-lg p-3 shadow animate__animated animate__bounceInDown">
                <div class="sm-form ">
                    <label for="nota">Nota</label>
                    <input type="text" disabled id="nota" name="nota" class="form-control form-control-sm" placeholder="nota" v-model="datawhislist.nota" >
                </div>
                <div class="sm-form ">
                    <label for="uang">Uang : </label>
                    <input type="number" id="uang" name="uang" class="form-control form-control-sm" placeholder="uang" v-model="uang" >
                </div>
                <div class="sm-form">
                <textarea
                type="text"
                id="keterangan"
                name="keterangan"
                rows="2"
                placeholder="keterangan..."
                class="form-control md-textarea"
                v-model="datawhislist.keterangan"
                ></textarea>
                </div>
                <hr>
                <p class="">total Rp. {{total}}</p>
                <p>Uang : {{uang}}</p>
                <!-- <button type="button" @click="diskon" :disabled="diskons" class="btn btn-sm btn-primary  ">Apply Diskon</button> -->
                <p v-show="uang-total">Kembalian : {{uang-total}}</p>
                <hr>
                <button type="button" id="btnbayar2" @click="bayar" class="btn btn-sm btn-dark  "><span class="typcn typcn-export"></span> Bayar</button>
            </div>
        </div>
    </div>
    <form  @submit.prevent="submit">
      <div class="row justify-content-center">
        <div class=" col-sm-5 p-2" style="font-size:12px;">
          <div class="shadow p-3 rounded-lg bg-white">
          <!-- INPUT -->
          <div class="sm-form">
            <input
              type="text"
              id="id"
              name="id"
              class="form-control form-control-sm"
              placeholder="id"
              v-model="datawhislist.id"
              disabled
            />
          </div>
          <table class="table table-sm table-responsive">
          <tr>        
             <td class="capitalize">tanggal</td>
             <td class="pl-2 pr-2">:</td>
             <td colspan="4">
                 <div class="sm-form ">
                     <input required type="datetime-local" id="tanggal" name="tanggal" class="form-control form-control-sm" placeholder="tanggal" v-model="datawhislist.tanggal" >
                 </div>
             </td>
         </tr>
          <tr>        
             <td class="capitalize">(F1) barcode</td>
             <td class="pl-2 pr-2">:</td>
             <td colspan="4">
                 <v-select id="f1" :options="produks" label="item_data" v-model="datawhislist.id_produk" @input="cekstok();hitung_total()" :reduce="e => e.id"></v-select>
                <!-- <select required class='form-control' v-model="datawhislist.id_produk" @change="cekstok">
                <option v-for="(item, index) in produks" :key="index+'pridils'" :value="item.id">{{item.barcode}}/{{item.nama_produk}}</option>
                </select> -->
             </td>
         </tr>
          <tr>        
             <td class="capitalize">Jumlah</td>
             <td class="pl-2 pr-2">:</td>
             <td colspan="4">
                 <div class="sm-form ">
                     <input required type="number" @change="hitung_total" :disabled='stok==0' min="1" :max="stok" id="jumlah" name="jumlah" class="form-control form-control-sm" placeholder="jumlah" v-model="datawhislist.jumlah" >
                 </div>
                 <div class="float-right">
                    <button type="button" class="btn btn-sm btn-dark  " id="tambah" @click="kurang">(F2) -</button>
                    <button type="button" class="btn btn-sm btn-dark  " id="kurang" @click="tambah">(F3) +</button>
                 </div>
                 <p class="text-xs text-red-300">Stok sisa : {{stok}}</p>
             </td>
         </tr>
          <tr>        
             <td class="capitalize">harga</td>
             <td class="pl-2 pr-2">:</td>
             <td>
                 <div class="sm-form "  v-if="$store.state.users">
                     <input type="number" :disabled="$store.state.users.level!='SuperAdmin'" @change="hitung_total" id="harga" name="harga" class="form-control form-control-sm" placeholder="harga" v-model="datawhislist.harga" >
                 </div>
             </td>
              <td class="capitalize">diskon</td>
             <td class="pl-2 pr-2">:</td>
             <td>
                 <div class="sm-form " v-if="$store.state.users">
                     <input type="number" :disabled="$store.state.users.level!='SuperAdmin'" @change="hitung_total" id="diskon" name="diskon" class="form-control form-control-sm" placeholder="diskon" v-model="datawhislist.diskon" >
                 </div>
             </td>
         </tr>
          <tr>        
             <td class="capitalize">total</td>
             <td class="pl-2 pr-2">:</td>
             <td class="font-bold text-lg" >
                Rp. {{datawhislist.total}}
             </td>
              <td class="capitalize">Satuan</td>
             <td class="pl-2 pr-2">:</td>
             <td class="text-xs" >
               <div v-if="$store.state.users">
                 <v-select :options="satuans" :disabled="$store.state.users.level!='SuperAdmin'" label="satuan" v-model="datawhislist.satuan" @input="hitung_total()" :reduce="e => e.id"></v-select>
               </div>
             </td>
         </tr>
          <tr>        
             <td class="capitalize">(F4) pelanggan</td>
             <td class="pl-2 pr-2">:</td>
             <td colspan="4">
                <select id="selectpelanggan" class='form-control' v-model="datawhislist.id_pelanggan" @change="cekmembership">
                <option v-for="(item, index) in pelanggans" :key="index+'pridils'" :value="item.id">{{item.nama}}</option>
                </select>
             </td>
         </tr>
          <tr v-if="datawhislist.id_pelanggan">        
             <td class="capitalize">Membership</td>
             <td class="pl-2 pr-2">:</td>
             <td>
               <span class="font-bold">
                 {{membership==0?'Bukan Membership':'Membership'}}
               </span>
               <span class="font-bold" v-if="membership==1">
                 <button type="button" class="btn btn-sm btn-primary  " @click="tambahDiskon">
                   {{diskon_membership}} %
                 </button>
               </span>
             </td>
             <td class="capitalize">Total Beli Pelanggan</td>
             <td class="pl-2 pr-2">:</td>
             <td>
               <span class="font-bold">
                 Rp. {{total_beli_pelanggan}}
               </span>
             </td>
         </tr>
         </table>
          <!-- END INPUT -->
          <!-- BUTTON PART -->
          <div class="mt-3 p-3">
            <button
              type="submit"
              class="btn btn-sm"
              id='btnsubmit'
              :class="
                btn == 'tambah'
                  ? 'btn-primary'
                  : btn == 'update'
                  ? 'btn-success'
                  : 'btn-danger'
              "
            >
              <span class="typcn typcn-info"></span> (F5) Proses {{ btn }}
            </button>
            <div class="float-right">
              <button
                type="button"
                v-show="btn!='tambah'"
                @click="
                  btn = 'tambah';
                  datawhislist = {};
                  skip_ambil_diskon=false;
                  getTanggal();
                  getNota();
                "
                class="btn btn-sm btn-dark"
              >
                <span class="typcn typcn-edit"></span> Tambah
              </button>
              <button
                type="button"
                @click="btn = 'delete'"
                v-show="btn!='tambah'"
                class="ml-3 btn btn-sm btn-danger"
              >
                <span class="typcn typcn-delete"></span> Delete
              </button>
            </div>
          </div>
          </div>
          <!-- END BUTTON PART -->
        </div>
        <div class=" col-sm-7 p-2" style="font-size:12px;">
          <div class="shadow bg-white rounded-lg p-2">
            <div v-if="datanya.length>0" class="">
            <button type="button" id="btnbayar" @click="modalbayar=true" class="btn btn-sm btn-style9   waves-effect float-right text-xs"><span class="typcn typcn-export"></span> Bayar</button>
              <button type="button" @click="deleteAll" class="btn btn-sm btn-dark  "><span class="typcn typcn-eject"></span> Delete All</button>
            <br>
            <br>
            <p class="font-bold">Nota Transaksi : {{datawhislist.nota}}</p>
                <Btables :tables="datanya"  :cari="['']" :hide="['id_produk','kategori','satuan','stok','keluar','id_user','tanggal_w']" @selected="ambil" ref="btable" />
            </div>
          </div>
        </div>
       
      </div>
    </form>
    <!-- TABLE PART -->
    <!-- END TABLE PART -->
     <div class="d-block d-sm-none"><br><br><br><br></div>
  </div>
</template>

<script>
// TOTURIAL 
// ganti source autophp, ganti source component btable, ganti path table sdb
import autophp from "@/plugins/autophp_kasir.js"; // ganti source nya
import Btables from "@/components/Btables.vue"; //component btable nya
let sdb = new autophp();
let sdb2 = new autophp();
import firebase from 'firebase'
let rdb=firebase.database()
import _ from 'lodash'
export default {
layout:'app_shop_admin',
  components: {
    Btables,
  },
  data() {
    return {
      selected: false,
      modalbayar:false,
      datawhislist: {
          jumlah:0
      },
      produks:[],
      pelanggans:[],
      btn: "tambah",
      stok:0,
      datanya:[],
      total:0,
      uang:'',
      diskons:false,
      skip_ambil_diskon:false,
      satuans:[],
      total_beli_pelanggan:0,
      membership:0,
      diskon_membership:0
    };
  },
  methods: {
      test(){
          alert('y')
      },
      async cekmembership(){
        let that=this;
        this.membership=await sdb.collection("app_kasir_pelanggan",false).doc().select(`select * from app_kasir_pelanggan where id=${this.datawhislist.id_pelanggan}`)
        console.log('membership',this.membership)
        this.membership=parseInt(this.membership[0].membership)
        let listmembership = await sdb.collection("app_kasir_pelanggan_membership",false).doc().select(`select * from app_kasir_pelanggan_membership`)
        let diskon
        this.total_beli_pelanggan=await sdb2.collection("app_kasir_transaksi_nota",false).doc().select(`select * from app_kasir_transaksi_nota where id_pelanggan='${this.datawhislist.id_pelanggan}'`)
        this.total_beli_pelanggan=this.total_beli_pelanggan.reduce((e,n)=>{
          return e+(parseInt(n.total))
        },0)
        if(this.membership!=0){
          diskon=listmembership.filter(e=>{
            if(this.total_beli_pelanggan>parseInt(e.total_beli)){
              return e
            }
          })
          this.diskon_membership=parseInt(diskon[0].diskon)
        }
        this.$forceUpdate();        
        
      },
      tambahDiskon(){
        this.datawhislist.diskon=parseInt(this.datawhislist.diskon)+parseInt(this.diskon_membership)
        this.hitung_total();
        this.$forceUpdate();
      },
      async tambahPointPelanggan(){
        if(parseInt(this.getJam())<16){ // jam pagi
          let data={}
          data.id = this.datawhislist.id_pelanggan
          let pelanggan=await sdb.collection("app_kasir_pelanggan",false).doc().select(`select * from app_kasir_pelanggan where id=${this.datawhislist.id_pelanggan}`)
          data.point=parseInt(pelanggan[0].point)+25
          console.log('data pelanggan',data)
          sdb.collection('app_kasir_pelanggan').doc().set(data).then(res=>{
              console.log(res)
          })
        }else if(parseInt(this.getJam())>16){ // jam sore/malam
           let data={}
          data.id = this.datawhislist.id_pelanggan
          let pelanggan=await sdb.collection("app_kasir_pelanggan",false).doc().select(`select * from app_kasir_pelanggan where id=${this.datawhislist.id_pelanggan}`)
          data.point=parseInt(pelanggan[0].point)+35
          console.log('data pelanggan',data)
          sdb.collection('app_kasir_pelanggan').doc().set(data).then(res=>{
              console.log(res)
          })
        }
    
      },
      tambah(){
        if(this.datawhislist.jumlah>parseInt(this.stok)-1){
          
          }else{
            this.datawhislist.jumlah=parseInt(this.datawhislist.jumlah)+1;
            this.hitung_total()
          }
      },
      kurang(){
        if(this.datawhislist.jumlah<1){

        }else{
          this.datawhislist.jumlah=parseInt(this.datawhislist.jumlah)-1;
          this.hitung_total()
        }
      },
      format(tanggal) {
        return this.$datefns.format(new Date(tanggal),"YYYY-MM-DD");
      },
      format2(tanggal) {
        return this.$datefns.format(new Date(tanggal),"YYYY-MM-DD-HH-mm");
      },
      getJam(tanggal) {
        return this.$datefns.format(new Date(),"HH");
      },
      getNota(){
          let code='KA';//KA = kasir, ON=Online, OF=OFFLINE
          sdb2.collection("app_kasir_transaksi_nota").doc().get("*",`where id_user='${this.$store.state.users.id}' order by id`).then(res => {
              console.log('getnota',res);
              this.datawhislist.nota=code+this.format(new Date()).split('-').join('')+'-'+this.$store.state.users.id+'-'+res.length;
              this.$forceUpdate();
          });
      },
     async cekstok(item){
        console.log(this.datawhislist)
          let stok=await sdb.collection("app_kasir_produk").doc().get("*",`where id='${this.datawhislist.id_produk}' order by id`).then(res => {
         if(!this.skip_ambil_diskon){
             this.datawhislist.diskon=res[0].diskon
         }
          this.datawhislist.harga=res[0].harga
          this.datawhislist.satuan=res[0].satuan
        return parseInt(res[0].stok)
      });
      let masuk = await sdb.collection("app_kasir_stok_masuk").doc().get("*",`where id_produk='${this.datawhislist.id_produk}' order by id`).then(res => {
        return res.reduce((e,n)=>{
          return e+parseInt(n.jumlah)
        },0)
      });
      let keluar = await sdb.collection("app_kasir_stok_keluar").doc().get("*",`where id_produk='${this.datawhislist.id_produk}' order by id`).then(res => {
        return res.reduce((e,n)=>{
          return e+parseInt(n.jumlah)
        },0)
      });
      
      // let transaksi=await sdb.collection("app_kasir_transaksi_nota",false).doc().select(`SELECT * FROM app_kasir_transaksi_nota tb1 left join app_kasir_keranjang tb2 on tb1.nota=tb2.nota where tb2.id_produk='${this.datawhislist.id_produk}' order by tb1.id`).then(res => {
      //      return res.reduce((e,n)=>{
      //       return e+parseInt(n.jumlah)
      //       },0)
      // });
       let keranjang=await sdb.collection("app_kasir_keranjang",false).doc().select(`SELECT * FROM app_kasir_keranjang  tb1 where tb1.id_produk='${this.datawhislist.id_produk}' order by tb1.id`).then(res => {
           return res.reduce((e,n)=>{
            return e+parseInt(n.jumlah)
            },0)
      });
      stok=stok+(masuk-keluar-keranjang)
      this.stok=stok;
      this.hitung_total();
      this.$forceUpdate();
    },
    hitung_diskon(){
        let data= this.datanya;
        if(data.length>0){
            this.total=data.reduce((e,n)=>{
                let diskon = (parseInt(n.diskon)/100)*parseInt(n.harga)
                return e+((parseInt(n.harga)*parseInt(n.jumlah))-diskon)
            },0)
            this.diskons=true;
        }
        this.$forceUpdate();
    },
    hitung_total(){
        let satuan=parseInt(this.satuans.filter(e=>e.id==this.datawhislist.satuan)[0].perkalian)
        this.datawhislist.total=(parseInt(this.datawhislist.harga)*satuan)*this.datawhislist.jumlah-((parseInt(this.datawhislist.diskon)/100)*parseInt(this.datawhislist.harga)*this.datawhislist.jumlah)
        this.$forceUpdate();
    },
    deleteAll(){
        if(confirm('apakah yakin menghapus semua data?')){
            let ids = [];
            ids=this.datanya.map(e=>e.id_user);
            sdb.collection("app_kasir_keranjang",false).doc().select(`DELETE FROM app_kasir_keranjang WHERE id_user IN (${ids.toString()}) AND posisi='keranjang'`).then(res => {
                console.log(res);
                this.getData();
            });
        }
    },
    deleteAll2(){
            let ids = [];
            ids=this.datanya.map(e=>e.id_user);
            sdb.collection("app_kasir_keranjang",false).doc().select(`DELETE FROM app_kasir_keranjang WHERE id_user IN (${ids.toString()}) AND posisi='keranjang'`).then(res => {
                console.log(res);
                this.getData();
            });
    },
    getData(){
      sdb.collection("app_kasir_keranjang",false).doc().select(`
      select tb1.id,tb1.id_user,tb2.nama_produk,tb1.nota,tb1.tanggal,tb1.id_pelanggan,tb2.barcode,tb1.harga,tb1.jumlah,tb1.id_produk,tb1.diskon,tb1.total,tb1.tanggal_w
      from app_kasir_keranjang tb1 left join app_kasir_produk tb2 on tb2.id=tb1.id_produk where tb1.id_user='${this.$store.state.users.id}' AND tb1.posisi='keranjang'`).then(res => {
        this.datanya=res;
        if(res.length>0){
            this.total=res.reduce((e,n)=>{
                return e+(parseInt(n.total))
            },0)
            let date = this.format(new Date()).split('-')[2]
            if(res[0].tanggal_w.split('-')[2]==date){
            }else{
                setTimeout(() => {
                    this.deleteAll2();
                }, 1000);
            }
            this.hitung_total();
        }
        this.$forceUpdate();
      });
      sdb.collection("app_kasir_produk").doc().get("*","where type='1' order by id").then(res => {
          this.produks=res;
          this.produks.map(function (x){ // taruh setelah produks di initial
                return x.item_data = x.barcode + ' / ' + x.nama_produk;
          });
      });
       sdb.collection("app_kasir_pelanggan").doc().get("*","order by id").then(res => {
          this.pelanggans=res;
      });
      
    },
    ambil(data) {
      this.datawhislist = data;
      this.cekstok();
      this.skip_ambil_diskon=true;
      console.log(data)
      this.btn = "update";
      this.$forceUpdate();
    },
    async bayar(){
      let that=this
      if(this.uang==0){
        alert('Uang tidak boleh 0')
      }else if(this.uang<this.total){
        alert('Uang tidak boleh kurang dari total harga')
      }
      else{
        if(confirm('Apakah yakin di proses ?')){
           let data = this.datanya;
            data=data.map(e=>{
                return e.id
            })
          let vdata={
            total:this.total,
            uang:this.uang,
            id_user:this.$store.state.users.id,
            id_pelanggan:this.datanya[0].id_pelanggan,
            tanggal_w:this.datanya[0].tanggal_w,
            cabang:this.$store.state.data.toko.cabang,
            keterangan:this.datawhislist.keterangan,
            nota:this.datawhislist.nota,
            tanggal:this.datawhislist.tanggal,
            status:'3',
            list_id:data.toString(),
          }
            let ids = [];
            ids=this.datanya.map(e=>e.id_user);
            console.log('vdata',vdata)
            sdb2.collection('app_kasir_transaksi_nota').doc().set(vdata).then(res=>{
              sdb.collection("app_kasir_keranjang",false).doc().select(`update app_kasir_keranjang set posisi='nota' WHERE id_user IN (${ids.toString()}) AND posisi='keranjang'`).then(res=>{
                  that.tambahPointPelanggan()
                  that.$router.push(`/admin/report/transaksi_now?nota=${that.datawhislist.nota}`)
              })
            })
        }
      }
    },
    async submit() {
      let that=this
      if(this.datawhislist.id_produk && parseInt(this.datawhislist.jumlah)>0 && this.datawhislist.id_pelanggan){
         let vdata={
           status:'keluar',
           tanggal_w:this.format(new Date(this.datawhislist.tanggal)),
           id_user:this.$store.state.users.id,
           posisi:'keranjang',
           beli:'kasir',
           cabang:this.$store.state.data.toko.cabang,
           diskon:this.datawhislist.diskon,
           harga:this.datawhislist.harga,
           id_pelanggan:this.datawhislist.id_pelanggan,
           id_produk:this.datawhislist.id_produk,
           jumlah:this.datawhislist.jumlah,
           tanggal:this.datawhislist.tanggal,
           nota:this.datawhislist.nota,
           total:this.datawhislist.total,
           satuan:this.datawhislist.satuan,
         }
          let cek = this.datanya.filter(e=>{
            if(e.id==that.datawhislist.id_produk){
              return e
            }
          })
          if (confirm("Apakah yakin proses dilanjutkan ?")) {
            if (this.btn == "tambah") {
              delete this.datawhislist.id;
             await sdb
                .collection("app_kasir_keranjang")
                .doc()
                .set(vdata)
                  that.getData();
                  that.cekstok();
                  let nota=that.datawhislist.nota;
                  that.datawhislist= {
                    jumlah:0,
                    nota:nota
                  }
                  that.getTanggal();
    
            } else if (this.btn == "update") {
              vdata.id=this.datawhislist.id
              await sdb
                .collection("app_kasir_keranjang")
                .doc()
                .set(vdata)
                  that.getData();
                  that.cekstok();
                   let nota=that.datawhislist.nota;
                  that.datawhislist= {
                    jumlah:0,
                    nota:nota
                  }
                  that.getTanggal();
            } else if (this.btn == "delete") {
              await sdb
                .collection("app_kasir_keranjang")
                .doc(this.datawhislist.id)
                .delete()
                  that.getData();
                  that.cekstok();
                    let nota=that.datawhislist.nota;
                  that.datawhislist= {
                    jumlah:0,
                    nota:nota
                  }
                  that.getTanggal();
            }
          }
      }else{
        sdb.alert('Isi Barcode, Jumlah, dan data pelanggan terlebih dahulu!')
      }
    },
    getTanggal(){
        this.datawhislist.tanggal=this.$datefns.format(new Date(),"YYYY-MM-DDTHH:mm")
    },
    getSatuan(){
      sdb.collection("app_kasir_produk_satuan").doc().get("*","order by id").then(res => {
          this.satuans=res;
          this.$forceUpdate();
      });
      
    }
  },
  async mounted() {
    let that=this;
    if(this.$store.state.users){
       rdb.ref(`/scan/${that.$store.state.users.id}/barcode`).on('value',(e)=>{ //read
           if(that.produks.length>0){
             console.log('produks',that.produks)
             let data =that.produks.filter(k=>{
               console.log(k.barcode)
               if(k.barcode==e.val().id){
                 return k
               }
             })
             console.log(data)
             that.datawhislist.id_produk=data[0].id
             that.cekstok();
             that.$forceUpdate();
           }
     }) 
        this.getNota();
        this.getData();
        this.getSatuan();
    }else{
      sdb.loadingOn();
      setTimeout(() => {
        rdb.ref(`/scan/${that.$store.state.users.id}/barcode`).on('value',(e)=>{ //read
             if(that.produks.length>0){
               console.log('produks',that.produks)
               let data =that.produks.filter(k=>{
                 console.log(k.barcode)
                 if(k.barcode==e.val().id){
                   return k
                 }
               })
               console.log(data)
               that.datawhislist.id_produk=data[0].id
               that.cekstok();
               that.$forceUpdate();
             }
       }) 
          this.getNota();
          this.getData();
          this.getSatuan();
      sdb.loadingOff();
      }, 3000);
    }
    this.getTanggal();
      document.addEventListener('keydown',function(e){
        if(e.key=='+' || e.key=='='){
          that.tambah()
        }else if(e.key=='-'){
          that.kurang()
        }
        if(e.key=='F1'){
          e.preventDefault()
          that.$el.querySelector('.vs__search').focus();
        }else if(e.key=='F2'){
          e.preventDefault();
          that.kurang()
        }else if(e.key=='F3'){
          e.preventDefault();
          that.tambah()
        }else if(e.key=='F4'){
          e.preventDefault();
          that.$el.querySelector('#selectpelanggan').focus();
        }else if(e.key=='F5'){
          e.preventDefault();
          that.$el.querySelector('#btnsubmit').click();
        }else if(e.key=='F6'){
          e.preventDefault();
          that.$el.querySelector('#btnbayar').click();
          setTimeout(() => {
            that.$el.querySelector('#uang').focus();
          }, 2000);
        }else if(e.key=='F7'){
          e.preventDefault();
          that.$el.querySelector('#keterangan').focus();
        }else if(e.key=='F8'){
          e.preventDefault();
          that.$el.querySelector('#btnbayar2').click();
        }
      })
  },
};
</script>